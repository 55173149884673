// export const normalizePhone = (value, previousValue) => {
//     if (!value) return value;
//     const currentValue = value.replace(/[^\d]/g, "");
//     const cvLength = currentValue.length;

//     if (cvLength < 4) return currentValue;
//     if (cvLength < 7)
//         return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`;
//     return `(${currentValue.slice(0, 3)})-${currentValue.slice(
//         3,
//         6
//     )}-${currentValue.slice(6, 10)}`;
// };

// export const validatePhone = (value) => {
//     let error = "";
//     const phoneRegex =
//         /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
//     if (!value) error = "Required!";
//     else if (value.length !== 14 || !phoneRegex.test(value))
//         error = "Invalid phone format. ex: (555)-555-5555";
//     return error;
// };

export function validatePassword(value) {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (!passwordRegex.test(value)) {
        return false;
    }
    return true;
}

const allReplace = (str, chars) => {
    var retStr = String(str);
    for (var x in chars) {
        retStr = retStr.replace(new RegExp(x, 'g'), chars[x]);
    }
    return retStr;
};

export const decodeHtmlEntity = (str) => {
    var chars = { '&amp;': '&', '&lt;': '<', '&gt;': '>', '&quot;': '"', '&#x27;': "'", '&#x2F;': "/", " &#x5C;": "\\" };
    return allReplace(str, chars)
    // return String(str).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#x27;/g, "'").replace(/&#x2F;/g, "/");
}

export const countryCurrencyCode = [
    { country: "US", currency: "$", code: "USD" },
    { country: "CA", currency: "C$", code: "CAD" },
    { country: "DE", currency: "€", code: "EUR" }, // Germany
    { country: "CH", currency: "CHF", code: "CHF" }, // Switzerland
    { country: "GB", currency: "£", code: "GBP" },
    { country: "FR", currency: "€", code: "EUR" }, // France
    { country: "ES", currency: "€", code: "EUR" },
    { country: "AU", currency: "A$", code: "AUD" },
    { country: "NZ", currency: "NZ$", code: "NZD" }, // New Zealand
    { country: "SG", currency: "S$", code: "SGD" }, // Singapore
    { country: "CZ", currency: "Kč", code: "CZK" },
];

export const countryPhoneCode = [
    { country: "US", code: "+1", label: "US (+1)" },
    { country: "CA", code: "+1", label: "CA (+1)" },
    { country: "DE", code: "+49", label: "DE (+49)" },
    { country: "CH", code: "+41", label: "CH (+41)" }, // Switzerland
    { country: "GB", code: "+44", label: "GB (+44)" },
    { country: "AU", code: "+61", label: "AU (+61)" },
    { country: "NZ", code: "+64", label: "NZ (+64)" }, // New Zealand
    { country: "ES", code: "+34", label: "ES (+34)" },
    { country: "CZ", code: "+420", label: "CZ (+420)" },
    { country: "FR", code: "+33", label: "FR (+33)" }, // France
    { country: "SG", code: "+65", label: "SG (+65)" }  // Singapore
];



export const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60 > 0 ? mins % 60 + "min" : ""
    return h > 0 ? `${h}h ${m}` : `${m}`
}

export const capitalizeFirstLetter = (string) => {
    const arr = string.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}