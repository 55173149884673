import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import { SmileTwoTone, ArrowLeftOutlined } from "@ant-design/icons";
import { resetCheckingInData } from "../../../redux/actions/checking-in/checking-in"
import { decodeHtmlEntity } from "../../../utils/util/utility"

class Thankyou extends Component {

    state = {
        secondCounter: 10
    }


    timer = () => {
        let { secondCounter } = this.state
        if (secondCounter > 0) {
            secondCounter = secondCounter - 1
            this.setState({
                secondCounter
            })
        } else {
            this.goToHomePage()
        }

    }

    componentDidMount() {
        this.id = setInterval(this.timer, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.id)
    }




    goToHomePage = () => {
        const location = this.props.match.params.location;
        this.props.resetCheckingInData()
        // return <Redirect to={`/${location}/checking-in/`}/>
        this.props.handleChangeCountry(this.props.business.country)
        this.props.history.push(`/${location}/checking-in/`);
    };

    render() {
        const { business } = this.props;
        const { secondCounter } = this.state
        return (
            <Row
                justify="center"
                style={{
                    padding: "10px 20px",
                    width: "100%",
                    position: "relative",
                    marginTop: "10%",
                }}
            >
                <Col md={18} lg={18} xl={18} sm={24} style={{ width: "100%" }}>
                    <div className="box box-down green animate__animated animate__fadeInUp">
                        {business && (
                            <h2 style={{ fontWeight: "bold" }}>
                                {business.name}
                            </h2>
                        )}

                        <h2 style={{ fontSize: "40px", textAlign: "center" }} className="animate__animated animate__zoomIn animate__delay-1s">

                            <SmileTwoTone twoToneColor="#52c41a" />  Thank you</h2>
                        <p style={{ fontSize: "1.3em", textAlign: "center", color: "#000000" }} >

                            Thank you for visiting <span style={{ fontWeight: "bold" }}> {decodeHtmlEntity(business.name)}</span>. Please have a seat
                            and we will serve you soon!
                        </p>
                        <Button type="primary" style={{ marginTop: "30px", height: "50px" }} className="btn-grad" size="large" onClick={() => this.goToHomePage()}>
                            <ArrowLeftOutlined /> Back To Home Page (in {secondCounter}s)
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
});

const mapDispatchToProps = (dispatch) => {
    return {
        resetCheckingInData: () => dispatch(resetCheckingInData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
